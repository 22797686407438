import { takeLatest, put, call, select } from "redux-saga/effects";
import { reset, change, initialize } from "redux-form";
import axios from "axios";
import MyDocument from '../exportpdf/mydocument';
import { pdf } from '@react-pdf/renderer';
import React, { Component } from "react";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

import { firebaseDatabase, firebaseStorage } from "../../controller/firebase";
import { ref, uploadBytes } from "firebase/storage";
import { ACTIONS_OF_APROVE, ANULACION_FORM_NAME, ANULAR_POSTULATION_FROM_TABLE, ASIGNA_ASESOR_FORM, DEVOLUCIONES_FORM_NAME, DEVOLVER_POSTULATION_FROM_TABLE, EXPORT_DATA_FORM_XLSX, HEADER_TABLE_PAGINATOR_VIVIENDA, HEADERS_WS, LOAD_CATALOG_BANDEJA, OPEN_ANULATION_FORM, OPEN_SEE_FILES_UNIFIED, PAGINAR_DATA_REG_VIVIENDA, REDUX_BLUR, REDUX_CHANGE, RETURN_HOME_BANDEJA, SAVED_ACTIONS_VALIDATION, SEARCH_FORM_FROM_TABLE, SEARCH_HISTORIAL_BANDEJA, VALIDATE_USER_TYPE } from "./constants";
import { consultaMsgParametrizado, conversion, convertirFecha, convertStringToDate, cuantosSalarios, dividePhone, emptyCero, emptyJson, emptyObject, enviarEmailFormatDocument, existLabel, filterCodeOportunidad, getAsyncCatalog, getAsyncUserRolFirebase, getBase64, getFechaOfDateV3, homologaSexo, manejoDeexepciones, mayor, notificaFalloWBS, ordenar, retornaIdCatalog, returnDataCatalog, saveHistoryEmpresas, saveHistoryViviendaActualizacion, sleep } from "../../controller/utils";
import { closeModales, errorGeneral, initBandejaAdministrador, initFormFromRevalidacion, loadDocumentSuccess, messageAlertSend, messageConfirmationSend, ocultarDevoluciones, openAnexosFromTable, openFormBandeja, openHistoryBandeja, openModalAnulationTable, openModalAsignaAsesorTable, openModalCharged, openModalDevolutionTable, savedInfoUserBandeja, setDataPaginarRegVivienda, typeUserBandejaVivienda } from "./actions";
import { getDynamicOptions, getFormValuesAnulacion, getFormValuesAsignacion, getFormValuesDevolucion, getMsgParametrizados, getSuc_id, getUserInfo, getUserTypeBandeja } from "./selectors";
import { updateState } from "../../controller/constants";
import { getIdFormulario } from "../vivienda/selectors";
import MyPdfCartas from "../exportpdf/pdfCartas";

function* generateMsgParametrizado(catalog, clave) {
    try {
        const message = yield consultaMsgParametrizado(catalog, clave);
        //yield put(updateMsgSolicitud(message));
        return message.text;
    } catch (error) {
        throw error;
    }
}

function* initValidateUser(action) {
    /*
  función que recibe los datos de la sesion de colaboradores
  crea el usuario y envia a buscar las solicitudes correspondientes por id_sucursal y/o por rol
  */
    try {
        const { value } = action;
        console.log(action)
        let userInfo = {
            email: emptyObject(value["email"]),
            emailVerified: emptyObject(value["email_verified"]),
            names: emptyObject(value["family_name"]),
            lasNames: emptyObject(value["given_name"]),
            idUser: emptyObject(value["https://test-empresas-comf.com/employ/id_usua"]),
            cellPhone: emptyObject(value["https://test-empresas-comf.com/employ/rest_celular"]),
            id: emptyObject(value["https://test-empresas-comf.com/employ/rest_id"]),
            tipoUser: emptyObject(value["https://test-empresas-comf.com/employ/tipo"]),
            tipoId: emptyObject(value["https://test-empresas-comf.com/employ/tipo_identificacion"]),
            nameCompleted: emptyObject(value["name"]),
            nickname: emptyObject(value["nickname"]),
            rol: "comercial",
            actionAnular: false
        }

        // let userInfo = {
        //     email: "pabfre9@gmail.com",
        //     emailVerified: true,
        //     names: "freire",
        //     lasNames: "pablo",
        //     idUser: "203431",
        //     cellPhone: "",
        //     id: "1109661476",
        //     tipoUser: "C",
        //     tipoId: "CC",
        //     nameCompleted: "pablo freire",
        //     nickname: "pabfre9",
        //     rol: "comercial"
        // }

        if (userInfo.email) {

            const user = yield getAsyncUserRolFirebase(userInfo.email);
            if (user) {
                console.log(user)
                userInfo.rol = user.rol === "comercial" ? "comercial" : "administrador";
                yield put(typeUserBandejaVivienda({ rol: userInfo.rol, idUsuaAsesor: userInfo.idUser, email: userInfo.email }));
                yield put(savedInfoUserBandeja(userInfo));
                yield obtenerDatosRegVivienda({ suc_id: "" })


            } else {
                //usuario sin permisos
                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgNotPermitAccessUser"),
                        error: true,
                    }))
            }
        }

    } catch (error) {
        console.error('saga.initValidateUser', error)
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}


function* generarQueryConsultaPaginada(reference, first, fecha, nextPage, rows) {
    /*
    función que genera la consulta paginada que se ejecuta sobre firebase para traer los 
    datos de la tabla principal de bandeja de comercial y rya
    */
    let consulta = reference

    if (first === 0) {

        if (fecha) {
            consulta = reference.limit(rows)
        } else if (!nextPage) {
            consulta = reference.limit(rows)
        } else {
            consulta = false
        }

    }
    else if (first > 0) {
        if (nextPage) {
            consulta = reference.startAfter(fecha).limit(rows)
        } else {
            consulta = reference.endBefore(fecha).limitToLast(rows)
        }
    }
    return consulta;
}

function getSheetData(data, header) {
    /*
función que realiza el mapeo de la información para crear el archivo excel
*/
    try {

        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        sheetData.unshift(header);
        return sheetData;
    } catch (error) {
        throw error;
    }
}

function* exportIntentToExcel(action) {
    /*
función que genera el archivo excel de la tabla bandeja
*/
    try {

        const { value: { dataToDownloadCsv } } = action;
        const jsonToExcel = dataToDownloadCsv;

        if (jsonToExcel.length > 0) {

            const header = []
            HEADER_TABLE_PAGINATOR_VIVIENDA.forEach(e => {
                header.push(e.header)
            });

            XlsxPopulate.fromBlankAsync().then(async (workbook) => {
                const sheet1 = workbook.sheet(0);
                const sheetData = getSheetData(jsonToExcel, header);
                const totalColumns = sheetData[0].length;

                sheet1.cell("A1").value(sheetData);
                const range = sheet1.usedRange();
                const endColumn = String.fromCharCode(64 + totalColumns);
                sheet1.row(1).style("bold", true);
                sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
                range.style("border", true);
                return workbook.outputAsync().then((res) => {
                    saveAs(res, "ActualizacionesVivienda.xlsx");
                });
            });

        }


    } catch (error) {
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* obtenerDatosRegVivienda(action, limit = 10) {
    /*
función que genera la tabla con las afiliaciones directamente al iniciar sesion de colaboradores
*/
    try {
        const { suc_id } = action;

        let reference;
        //vamos a consultar los primeros 10 registros de empresas
        let resultadoConsulta = []
        const userType = yield select(getUserTypeBandeja);

        if (userType && userType.rol === 'comercial') {
            reference = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .where('state', "in", [updateState.VALIDACION, updateState.RE_VALIDACION, updateState.ANULADA, updateState.DEVUELTA, updateState.FINALIZADA])
                .where('asesorComercial.email', '==', userType.email)
                .orderBy('date', 'asc').limit(limit)
                .get();

        } else if (userType && userType.rol === "administrador") {
            reference = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .where('state', "in", [updateState.BORRADOR, updateState.UNIFICACION, updateState.VALIDACION, updateState.RE_VALIDACION, updateState.ANULADA, updateState.DEVUELTA, updateState.FINALIZADA])
                .orderBy('date', 'asc').limit(limit)
                .get();

            yield put(initBandejaAdministrador(true));
        }

        if (reference) {
            reference.docs.map((doc, i) => {
                let dataAfilia = doc.data();
                dataAfilia.idFormulario = doc.id;
                resultadoConsulta.push(dataAfilia)
            });
        }
        if (resultadoConsulta.length > 0) {

            const dataFinal = resultadoConsulta;

            let data = yield crearDataTable({ data: dataFinal, coleccion: "regVivienda" });

            yield put(setDataPaginarRegVivienda(
                {
                    "dataRegVivienda": data,
                    "esPrimeraPagina": true
                }
            ));
        } else {
            // no hay afiliaciones para esa sucursal a la que pertenece
            yield put(setDataPaginarRegVivienda(
                {
                    "dataRegVivienda": [],
                    "esPrimeraPagina": true
                }
            ));
        }

    } catch (error) {
        console.error('saga.obtenerDatosRegVivienda', error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* onReduxChange(action) {
    const { meta: { field, form }, payload, } = action;
    try {
        let data = {};
        switch (field) {
            case "datosanulacion.motivoAnulacion":
                yield put(initialize(DEVOLUCIONES_FORM_NAME, {}));
                return yield put(ocultarDevoluciones(true));

            case "datosanulacion.observationAnulation":
                yield put(initialize(DEVOLUCIONES_FORM_NAME, {}));
                return yield put(ocultarDevoluciones(true));;

            case "datosdevolucion.motivoDevolucion":
                return yield put(initialize(ANULACION_FORM_NAME, {}));

            case "datosdevolucion.observation":
                return yield put(initialize(ANULACION_FORM_NAME, {}));

            case "datosasignacion.asesorDisponible":
                return yield put(change(ANULACION_FORM_NAME, "datosasignacion.emailAsesor", payload.value));

            default:
                return;
        }



    } catch (error) {
        console.error("Saga.onReduxChange", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* onreduxBlur(action) {
    const { meta: { field, form }, payload, } = action;
    try {

        const fields = field.split('.');
        let data = "";
        let dynamicOptions = false;
        switch (fields[1]) {

            case "motivoDevolucion":
                dynamicOptions = yield select(getDynamicOptions);
                if (!existLabel(dynamicOptions["motivoDevolucion"], payload))
                    yield put(change(DEVOLUCIONES_FORM_NAME, field, ""))
                return;

            case "motivoAnulacion":
                dynamicOptions = yield select(getDynamicOptions);
                if (!existLabel(dynamicOptions["motivoAnulacion"], payload))
                    yield put(change(ANULACION_FORM_NAME, field, ""))
                return;

            default:
                return;
        }

    } catch (error) {
        console.error("Saga.reduxblur", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* crearDataTable(action) {
    /*
    Función que genera las tablas con respecto a la información dada 
    respecto a el tipo de coleccion que se le pasa, se usa para la tabla de bandeja y la tabla de informes
    */

    const { data, coleccion } = action;
    const datos = [];
    try {
        if (data.length > 0) {
            let dynamicOptions = yield select(getDynamicOptions);
            //const sedes = yield getAsyncCatalog('SEDES_REGIONALES');
            // let catalog = yield select(getDynamicOptions);
            // if (!catalog) {
            //     yield put(loadDocumentSuccess({ sedes }));
            // } else {
            //     catalog.sedes = sedes;
            //     yield put(loadDocumentSuccess(catalog));
            // }

            if (coleccion === "regVivienda") {

                for (var i of data) {

                    const middleName = i.step1.datospostulante.middleName ? ` ${i.step1.datospostulante.firstName}` : "";

                    datos.push({
                        dateUpdate: i.date ? convertirFecha(i.date) : "",
                        oportunidad: emptyObject(i.oportunidad),
                        idUser: `${emptyObject(i.step1.datospostulante.documentType)} - ${emptyObject(i.userid)}`,
                        nameUser: `${emptyObject(i.step1.datospostulante.firstName)}${emptyObject(middleName)} ${emptyObject(i.step1.datospostulante.lastName)}`,
                        cellPhone: emptyObject(i.step1.datospostulante.companyPhone),
                        dateManage: i.date ? convertirFecha(i.date) : "",
                        asesor: i.asesorComercial?.email ? i.asesorComercial.email : "sin asignar",
                        stateLabel: i.state === 0 ? "Borrador" : returnDataCatalog(dynamicOptions.states, i.state, "value", "label"),
                        state: i.state,
                        idFormulario: i.idFormulario,
                        refeFiles: i.refFiles?.refStorage.length > 0 ? i.refFiles.refStorage : [],
                        step1: i.step1,
                    })
                }
            }

            if (coleccion === "reportsCompany") {

                // for (var i of data) {
                //     // const typeCompany = emptyObject(i.tipoEmpleador) === 1 ? "Persona Jurídica" : emptyObject(i.tipoEmpleador) === 2 ? "Persona Natural" : "Servicio Doméstico";
                //     // const auxState = yield returnDataCatalogV2(catalog.states, parseInt(i.estado), "value", "label");

                //     datos.push({

                //         nroOportunidad: emptyObject(i.idOportunidad),
                //         dateCreate: getFechaOfDateV4(emptyObject(i.fechaCreacion?.value)),
                //         dateModifica: getFechaOfDateV4(emptyObject(i.fechaModificacion?.value)),
                //         dateSend: getFechaOfDateV4(emptyObject(i.fechaFirma?.value)),
                //         dateReSend: getFechaOfDateV4(emptyObject(i.fechaReenvio?.value)),
                //         idDocu: emptyObject(i.idDocu),
                //         state: `${primeraMayuscula(auxState.toLowerCase())}`,
                //         idCompany: emptyObject(i.idCompany),
                //         razonSocial: emptyObject(i.razonSocial),
                //         empleadorType: typeCompany,
                //         nroEmployer: emptyObject(i.numeroEmpleados),
                //         dateValidCmr: i.fechaFinValidaComercial?.value ? getFechaOfDateV4(emptyObject(i.fechaFinValidaComercial?.value)) : getFechaOfDateV4(emptyObject(i.fechaValidaComercial?.value)),
                //         userValidCmr: emptyObject(i.userValidComercial),
                //         dateValidaRya: i.fechaFinValidaRya?.value ? getFechaOfDateV4(emptyObject(i.fechaFinValidaRya?.value)) : getFechaOfDateV4(emptyObject(i.fechaValidaRya?.value)),
                //         userValidaRya: emptyObject(i.usuarioValidaRya),
                //         dateAfilia: getFechaOfDateV4(emptyObject(i.fechaAfiliacion?.value)),
                //         userAfilia: emptyObject(i.userAfilia),
                //         dateDevolCmr: getFechaOfDateV4(emptyObject(i.fechaDevolucion?.value)),
                //         userDevolCmr: emptyObject(i.userDevol),
                //         motivoDevolCmr: emptyObject(i.motivoDevol),
                //         obsDevolCmr: emptyObject(i.observacionDevol),
                //         dateDevolRya: getFechaOfDateV4(emptyObject(i.fechaDevolucionComercial?.value)),
                //         userDevolRya: emptyObject(i.userDevolCmr),
                //         motivoDevolRya: emptyObject(i.motivoDevolCmr),
                //         obsDevolRya: emptyObject(i.observacionDevolCmr),
                //         dateAnula: getFechaOfDateV4(emptyObject(i.fechaAnulacion?.value)),
                //         userAnula: emptyObject(i.userAnula),
                //         motivoAnula: emptyObject(i.motivoAnulacion),
                //         obsAnula: emptyObject(i.observacionAnulacion),
                //         idFirebase: emptyObject(i.id),
                //     })
                // }
            }
        }
        return datos;//.sort(ordenarFecha);


    } catch (error) {
        throw error;
    }
}

function* paginarDataRegVivienda(action) {
    /*
    función que realiza la consulta paginada de la tabla principal de bandeja de comercial y rya
    obedece al evento del usuario
*/
    try {

        let reference = false;
        let data = false;

        const { first, rows } = action.value.event;
        const { ultimaConv, nextPage } = action.value;


        let ultimaConvFecha = convertStringToDate(ultimaConv.dateUpdate)

        const userType = yield select(getUserTypeBandeja);


        if (userType && userType.rol === "comercial") {

            reference = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .where('state', "in", [updateState.VALIDACION, updateState.RE_VALIDACION, updateState.ANULADA, updateState.DEVUELTA, updateState.FINALIZADA])
                .where('asesorComercial.email', '==', userType.email)
                .orderBy('date', 'asc');

        } else if (userType && userType.rol === "administrador") {
            reference = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .where('state', "in", [updateState.BORRADOR, updateState.UNIFICACION, updateState.VALIDACION, updateState.RE_VALIDACION, updateState.ANULADA, updateState.DEVUELTA, updateState.FINALIZADA])
                .orderBy('date', 'asc');
        }


        //const snapshot = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_COMPANY).doc("ER6KprrabX4yKIf4oC9d");
        let query = yield generarQueryConsultaPaginada(reference, first, ultimaConvFecha, nextPage, rows);

        let resultadoConsulta = []
        let esPrimeraPagina = false;
        if (query) {

            yield query.get().then(response => {
                response.docs.map((doc, i) => {
                    let dataAfilia = doc.data();
                    dataAfilia.idFormulario = doc.id;
                    resultadoConsulta.push(dataAfilia)
                });

            })

            const dataFinal = resultadoConsulta;

            data = yield crearDataTable({ data: dataFinal, coleccion: "regVivienda" });


        } else {
            esPrimeraPagina = true
        }

        yield put(setDataPaginarRegVivienda(
            {
                "dataPaginada": data,
                "esPrimeraPagina": esPrimeraPagina
            }
        ));
    } catch (error) {
        console.error("saga.paginarDataRegVivienda", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }

}

function* searchFormFromTable(action) {
    /*
función que abre la postulación correspondiente a la seleccion del asesor
*/
    try {
        console.log("searchFormFromTable", action)
        const { value } = action;
        yield put(openFormBandeja({ state: true, idForm: value.idFormulario }));
        if (value.state === 3)
            yield put(initFormFromRevalidacion(true))
        const userInfo = yield select(getUserInfo);
        const idFormulario = yield select(getIdFormulario);
        const history = {
            fechaHistory: new Date(),
            tomado: userInfo.rol,
            infoUsuario: userInfo,
            resultadoValidacion: {
                fechaValidacion: new Date(),
                accionRealizada: "Revisado por asesor"
            },
        }
        saveHistoryViviendaActualizacion({ history, idFormulario })

        if (userInfo.rol === "comercial") {
            const validCmr = {
                fechaIni: history.fechaHistory,
                user: userInfo,
                fechaFin: history.fechaHistory,
            }
            yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .doc(idFormulario).set({ validComercial: validCmr }, { merge: true });
        }
        else {
            const validAdmin = {
                fechaIni: history.fechaHistory,
                user: userInfo,
                fechaFin: history.fechaHistory,
            }
            yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .doc(idFormulario).set({ validAdmin: validAdmin }, { merge: true });
        }
    } catch (error) {
        console.error('saga.searchFormFromTable', error)
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* openSeeFilesUnified(action) {
    /*
función que abre el archivo correspóndiente a los anexos unificados de la postulacion
*/
    console.log("openSeeFilesUnified", action)
    try {
        const { value: { refeFiles } } = action;
        const files = refeFiles.filter(e => e.classification === "anexoUnificado" || e.classification === "formularioAfiliacion");
        console.log(files)

        yield put(openAnexosFromTable(files));

    } catch (error) {
        console.error('saga.openSeeFilesUnified', error)
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* devolverPostulationTable(action) {
    /*
función que genera la devolucion de una postulacion
*/  console.log("devolverPostulationTable", action);
    try {
        yield put(openModalDevolutionTable(action.value));
    } catch (error) {
        console.error('saga.devolverPostulationTable', error)
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

function* anularPostulationTable(action) {
    /*
función que genera la anulacion de una postulacion
*/console.log(action)
    try {
        yield put(openModalAnulationTable(action.value))

    } catch (error) {
        console.error('saga.anularPostulationTable', error)
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}


function* searchHistorialform(action) {
    /*
función consulta el historial de una afiliacion y crea el json con la informacion a mostrar
*/
    const { value } = action;

    try {
        let resultadoConsulta = []
        const reference = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA).doc(value).collection("history")
            .orderBy('fechaHistory', 'asc')
            .get();
        reference.docs.map((doc, i) => {
            resultadoConsulta.push(doc.data())
        });
        let historico = []
        if (resultadoConsulta.length > 0) {
            resultadoConsulta.forEach(e => {
                historico.push({
                    date: convertirFecha(e.fechaHistory),
                    name: emptyObject(e.infoUsuario.nameCompleted),
                    tomado: emptyObject(e.tomado),
                    rol: emptyObject(e.infoUsuario.rol),
                    email: emptyObject(e.infoUsuario.email),
                    action: emptyObject(e.resultadoValidacion.accionRealizada),
                    motivo: emptyObject(e.resultadoValidacion.motivo),
                    observation: emptyObject(e.resultadoValidacion.observacion ? e.resultadoValidacion.observacion : e.resultadoValidacion.observation ? e.resultadoValidacion.observacion : ""),
                    refFile: emptyObject(e.resultadoValidacion.refFil)
                })
            });

            yield put(openHistoryBandeja(historico));
        }
    } catch (error) {
        console.error("Saga.savedActionsHistory", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }


}

function* onActionsAprove(action) {
    try {
        const { value: { type, from, data } } = action;
        console.log(action)
        switch (type.toUpperCase()) {
            case "DEVOLVER":
                const dataDevolver = yield select(getFormValuesDevolucion);

                if (validateDevolverForm(dataDevolver))

                    yield put(messageConfirmationSend({
                        message: `¿Está seguro de realizar la devolución? Motivo: ${dataDevolver.datosdevolucion.motivoDevolucion}`,
                        actionValid: type,
                        data: dataDevolver,
                        dataForm: data,
                        from
                    }));
                else
                    yield put(messageAlertSend(
                        {
                            message: "Debe diligenciar los datos de la devolución antes de continuar",
                            error: true,
                        }));
                return;

            case "ANULAR":
                const dataAnular = yield select(getFormValuesAnulacion);

                if (validateAnularForm(dataAnular))
                    yield put(messageConfirmationSend({
                        message: `Esta seguro de continuar con la anulación con motivo: ${dataAnular.datosanulacion.motivoAnulacion}`,
                        actionValid: type,
                        data: dataAnular,
                        dataForm: data,
                        from
                    }));
                else
                    yield put(messageAlertSend(
                        {
                            message: "Debe diligenciar los datos de la anulación antes de continuar",
                            error: true,
                        }));
                return;

            case "AFILIAR":
                yield put(messageConfirmationSend({
                    message: "Esta seguro de finalizar la actualización de esta postulación",
                    actionValid: type,
                    data: false,
                    from
                }));
                return;

            case "ASIGNAR":
                const dataAsignar = yield select(getFormValuesAsignacion);

                if (validateAsignarForm(dataAsignar))

                    yield put(messageConfirmationSend({
                        message: `¿Está seguro de asignar a: ${dataAsignar.datosasignacion.asesorDisponible} la solicitud de actualización seleccionada?`,
                        actionValid: type,
                        data: dataAsignar,
                        dataForm: data,
                        from
                    }));
                else
                    yield put(messageAlertSend(
                        {
                            message: "Debe seleccionar un asesor antes de continuar",
                            error: true,
                        }));
                return;
            default:
                return;
        }

    } catch (error) {
        console.error("Saga.onActionsAprove", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}


function validateDevolverForm(action) {
    try {
        return (!action || !action.datosdevolucion["motivoDevolucion"] || !action.datosdevolucion["observation"]) ? false : true;
    } catch (error) {
        console.error("Saga.validateDevolverForm", error);
        throw error;
    }
}

function validateAsignarForm(action) {
    try {
        return (!action || !action.datosasignacion["asesorDisponible"]) ? false : true;
    } catch (error) {
        console.error("Saga.validateAsignarForm", error);
        throw error;
    }
}

function validateAnularForm(action) {
    try {
        return (!action || !action.datosanulacion["motivoAnulacion"] || !action.datosanulacion["observationAnulation"]) ? false : true;
    } catch (error) {
        console.error("Saga.validateAnularForm", error);
        throw error;
    }
}

function* recoveryTableAfiliations() {
    try {
        const suc_id = yield select(getSuc_id);
        yield obtenerDatosRegVivienda({ suc_id });
    } catch (error) {
        console.error("Function.recoveryTableAfiliations", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}



function* buildDynamicOptions(action) {
    try {

        const cargado = yield select(getDynamicOptions);
        if (!(cargado !== undefined && cargado.motivoDevolucion)) {
            let dynamicOptions = {};
            const devoluciones = yield getAsyncCatalog('MOTIVOS_DEVOLUCION');
            const anulacion = yield getAsyncCatalog('MOTIVOS_ANULACION');
            const stateAfilia = yield getAsyncCatalog('ESTADOS_AFILIACION');
            const users = yield getAsyncCatalog('USER_ROL_BANDEJA_VIVIENDA');
            let newUsers = []
            if (users && users.length > 0) {
                users.forEach(e => {
                    newUsers.push({
                        label: emptyObject(e.name),
                        value: emptyObject(e.email)
                    })
                });
            }
            dynamicOptions.motivoAnulacion = anulacion.sort(ordenar);
            dynamicOptions.motivoDevolucion = devoluciones.sort(ordenar);
            dynamicOptions.states = stateAfilia;
            dynamicOptions.asesorDisponible = newUsers.sort(ordenar);


            const res = yield put(loadDocumentSuccess(dynamicOptions));
        }

    } catch (error) {
        console.error("Function.buildDynamicOptions", error);
        throw error;
    }
}

function* onOpenAnulationForm(action) {
    try {

        let dynamicOptions = yield select(getDynamicOptions);


        if (!(dynamicOptions && dynamicOptions.motivoAnulacionForm)) {
            const stateAfilia = yield getAsyncCatalog('ESTADOS_AFILIACION');
            const anulacion = yield getAsyncCatalog('MOTIVOS_ANULACION');
            dynamicOptions.motivoAnulacionForm = anulacion.sort(ordenar);
            dynamicOptions.states = stateAfilia;


            const res = yield put(loadDocumentSuccess(dynamicOptions));
        }
    } catch (error) {
        console.error("Function.onOpenAnulationForm", error);
        throw error;
    }
}
function* onAsignaAsesorForm(action) {
    try {
        console.log("onAsignaAsesorForm", action);
        yield put(openModalAsignaAsesorTable(action.value))
    } catch (error) {
        console.error("Function.onAsignaAsesorForm", error);
        throw error;
    }
}

function* consumeServiceGenerico(action) {
    try {
        const { body, url } = action;


        const request = { urlServicio: url, body: body }
        const headers = HEADERS_WS;
        const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);
        return respues.data;
    } catch (error) {
        console.error("Function.consumeServiceGenerico", error);
        throw error;
    }

}

function* consumeActualizaOportunidad(action) {
    /*Función que actualiza la oportunidad
     *Recibe, el tipo de identificacion y el numero, la fase de venta, y una nota si la hay
     *Si el formulario genero algun error se envia un json diferente 
     */
    console.log(action)
    const { faseVenta, anexos, itemsForm, nota, idMotivo, estado } = action;
    const id = itemsForm.step1.datospostulante.numberId;
    const tipo = itemsForm.step1.datospostulante.typeIdCompany;
    const idOportunidad = itemsForm.oportunidad;

    const messageid = tipo + id;

    var date = "";
    var date = new Date();
    var archivo = [];

    var infoOportu = {
        "idOportunidad": idOportunidad,
        "faseVenta": null,
        "archivo": archivo,
        //"idMotivo": idMotivo ? idMotivo : "",
        "estado": null,
        "nota": nota //tipo id nombre correo celular cargo \n tipo id nombre correo celular cargo
    }

    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;

    const body = {
        "requestMessageOut": {
            "header": {
                "invokerDateTime": date,
                "moduleId": "OPORTUNIDAD",
                "systemId": "FORMULARIOSWEB",
                "messageId": messageid,
                "logginData": {
                    "sourceSystemId": "",
                    "destinationSystemId": ""
                },
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMCreaActualizaOportunidad",
                    "name": "CRMCreaActualizaOportunidad",
                    "operation": "execute"
                },
                "securityCredential": {
                    "userName": "",
                    "userToken": ""
                },
                "classification": {
                    "classification": ""
                }
            },
            "body": {
                "request": {
                    "oportunidad": {
                        "accion": "2",
                        "infoOportunidad": infoOportu,
                    }
                }
            }
        }
    }
    var resul = false;
    try {
        console.time("inicio");
        console.log("body oportunidad", JSON.stringify(body))
        const res = yield consumeServiceGenerico({ body, url: urlConfenalco });
        console.log("response oportunidad", res)

        // const idForm = yield select(getIdFormulario);
        let status = "FAILURE";
        if (res.responseMessageOut.header.responseStatus.statusCode === "SUCESS" && res.responseMessageOut.body.response.mensaje === "ok") {
            //TODO: capturar el mensaje de exito
            //Oportunidad actualizada con exito
            resul = true;
            status = "SUCESS";
        } else {
            //TODO: capturar la falla y el proceso a seguir
            notificaFalloWBS({ body, webService: "Actualiza la oportunidad", response: res.responseMessageOut.body })
        }
        // saveBdWebService(idForm, "CRMCreaActualizaOportunidad", { ...body }, status, res.responseMessageOut.body);

        console.timeEnd("inicio");
        return resul;
    } catch (error) {
        console.error("actualizaOportunidad - exception:", error);
        throw error;
    }

}

function* consumeConsultaCodeOportunidad(action) {
    /*Función que actualiza la oportunidad
     *Recibe, el tipo de identificacion y el numero, la fase de venta, y una nota si la hay
     *Si el formulario genero algun error se envia un json diferente 
     */

    const { itemsForm } = action;
    const id = itemsForm.step1.datospostulante.numberId;
    const tipo = itemsForm.step1.datospostulante.typeIdCompany;
    const idOportunidad = itemsForm.oportunidad;

    const messageid = tipo + id;

    var date = "";
    var date = new Date();

    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;

    const body = {
        "requestMessageOut": {
            "header": {
                "invokerDateTime": date,
                "messageId": messageid,
                "moduleId": "VIVIENDA",
                "systemId": "PORTAL",
                "logginData": {
                    "sourceSystemId": null,
                    "destinationSystemId": null
                },
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/InQueueCRMFormularioSubsidioVivienda",
                    "name": "CRMFormularioSubsidioVivienda",
                    "operation": "execute"
                },
                "classification": {
                    "classification": null
                }
            },
            "body": {
                "request": {
                    "actionCode": "10",
                    "zCodigoOportunidad": idOportunidad
                }
            }
        }
    }
    var resul = false;
    try {
        console.time("inicio");
        console.log("body code oportunidad", JSON.stringify(body))
        const res = yield consumeServiceGenerico({ body, url: urlConfenalco });
        console.log("response conde oportunidad", res)

        // const idForm = yield select(getIdFormulario);
        let status = "FAILURE";
        if (res.responseMessageOut.header.responseStatus.statusCode === "SUCCES" && res.responseMessageOut.body.response.registroSubsidioResponse) {
            //TODO: capturar el mensaje de exito
            //Oportunidad actualizada con exito
            resul = res.responseMessageOut.body.response.registroSubsidioResponse;
            status = "SUCESS";
        } else {
            //TODO: capturar la falla y el proceso a seguir
            notificaFalloWBS({ body, webService: "Actualiza la oportunidad", response: res.responseMessageOut.body })
        }
        // saveBdWebService(idForm, "CRMCreaActualizaOportunidad", { ...body }, status, res.responseMessageOut.body);

        console.timeEnd("inicio");
        return resul;
    } catch (error) {
        console.error("consumeConsultaCodeOportunidad - exception:", error);
        throw error;
    }

}

function* consumeWsVivienda(action) {
    const { oportunidad, items, idFormulario, catalogo, codes } = action;
    console.log(action)
    const { step1, step2, step3, step4 } = items;
    const id = step1.datospostulante.numberId;
    const tipo = step1.datospostulante.documentType;
    const messageid = tipo + id;
    var date = "";
    var date = new Date();
    const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
    const inthogarPostulan = [];


    var modalidad = "";
    const valModali = step3?.informacionbasica?.homeModeType;
    catalogo.homeModeType.forEach(element => {
        if (element.value === valModali) {
            modalidad = element.label;
        }
    });

    var laEMPname = '';
    var laEMPplace = '';
    var laEMPphone = '';

    if (step1?.informacionlaboral?.laborandoSelect) {
        if (step1.informacionlaboral.laborandoSelect === "otraEmpresa") {
            laEMPname = emptyObject(step1.informacionlaboral.companyJobName);
            laEMPplace = emptyObject(step1.informacionlaboral.companyJobAddress);
            laEMPphone = emptyObject(step1.informacionlaboral.companyPhoneJob);
        } else {
            laEMPname = emptyObject(step1.informacionlaboral.subsidioData.name);
            laEMPplace = emptyObject(step1.informacionlaboral.subsidioData.place);
            laEMPphone = emptyObject(step1.informacionlaboral.subsidioData.phone);
        }
    }

    if (step2?.integrantesHogar?.inthogarpostulantes) {
        step2.integrantesHogar.inthogarpostulantes.forEach(element => {
            //TODO: validar campos no obligatorios
            let codeInth = ""

            if (codes.IntegrantesHogarPostulante && codes.IntegrantesHogarPostulante.length > 0) {
                let aux = codes.IntegrantesHogarPostulante.find(c => c.id === element.idPostu);

                if (aux !== undefined) {
                    codeInth = aux.code;
                }
            }
            inthogarPostulan.push({
                "SAP_UUID": codeInth,
                "zNombreApellido": element.namePostu,
                "zFechaNacimiento": element.birthdatePostu,
                "zTipoDocumento": element.documentTypePostu,
                "zDocIdentididadAfiliado": element.idPostu,
                "zSexoAfiliado": homologaSexo(element.sexPostu),
                "zParentescoAfil": element.relativePostu,
                "zOcupacionAfil": element.jobPostu,
                "zEstadoCivilAfil": element.maritalstatusPostu,
                "zipoPostulanteAfil2": emptyObject(element.postulantePostu),
                "zIngresoMensualAfil2": {
                    "value": conversion(element.ingresosMensual),
                    "currencyCode": "COP"
                },
                "zCondicionEspecialAfil": emptyObject(element.specialPostu),
                "zNombreCaja": element.nombrecajaPostu
            });
        });
    }

    var modalidadvivienda = {}
    if (step3.modalidadvivienda && step3.modalidadvivienda.projecttype) {
        modalidadvivienda = {
            "SAP_UUID": codes["ModalidadViviendaV2"],
            "Code": step3.modalidadvivienda.projecttype,
            "zPropiedadLote": step3.modalidadvivienda.propiedadlote,
            "zNumeroMatriculaInmob": step3.modalidadvivienda.predioaddress,
            "zDireccionLote": step3.modalidadvivienda.loteaddress,
            "zMunicipioMovil": step3.modalidadvivienda.cityModalidad,
            "zNombreUrbanizacion": step3.modalidadvivienda.urbanizacion,
            "zManzana": step3.modalidadvivienda.apple,
            "zNroLote": step3.modalidadvivienda.lotenumber,
            "zArealote": emptyCero(step3.modalidadvivienda.areaLoteM2),
            "zFrente": emptyCero(step3.modalidadvivienda.frenteLote),
            "zFondo": emptyCero(step3.modalidadvivienda.fondoLote),
            "zLicenciaConstruccion": step3.modalidadvivienda.licencia,
            "zVencimiento": step3.modalidadvivienda.vencimientoLicencia,
            "zEscritura": step3.modalidadvivienda.escritura,
            "zFechaEscritura": step3.modalidadvivienda.escriturafecha,
            "zNotaria": step3.modalidadvivienda.notariaEscritura,
            "zFechaRegistro": step3.modalidadvivienda.registertestdate
        }
    } else {
        modalidadvivienda = {
            "SAP_UUID": codes["ModalidadViviendaV2"],
            "Code": null,
            "zPropiedadLote": null,
            "zNumeroMatriculaInmob": null,
            "zDireccionLote": null,
            "zMunicipioMovil": null,
            "zNombreUrbanizacion": null,
            "zManzana": null,
            "zNroLote": null,
            "zArealote": "0.00",
            "zFrente": "0.00",
            "zFondo": "0.00",
            "zLicenciaConstruccion": null,
            "zVencimiento": null,
            "zEscritura": null,
            "zFechaEscritura": null,
            "zNotaria": null,
            "zFechaRegistro": null
        }
    }
    var recursoseconomicos = {
        "SAP_UUID": codes["RecursoEconomico"],
        "zCuentaAhorroProg": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zApotesPeriodicos": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zCuentaAhorroContractual": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zCesantias": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zAporteLoteTerreno": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zAporteAvance": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zCuotaInicial": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zOtrosRecursos": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zTotalAhorroPrevio": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zCreditoAprobado": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zAportesSolitarios": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zAportesEnteTerr": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zDonacion": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zOtrosRecursosComple": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zTotalRecursos": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zCuentaAhorroProgFecha": null,
        "zCuentaAhorroProgEntidadID": null,
        "zAportesPeriodicosFecha": null,
        "zAportesPeriodicosEntidadID": null,
        "zCuentaAhorroContractualFecha": null,
        "zCuentaAhorroContractualEntidadID": null,
        "zCesantiasFecha": null,
        "zCesantiasEntidadID": null,
        "zAporteLoteTerrenoFecha": null,
        "zAporteLoteTerrenoEntidadID": null,
        "zAporteAvanceFecha": null,
        "zAporteAvanceEntidadID": null,
        "zCuotaInicialFecha": null,
        "zCuotaInicialEntidadID": null,
        "zOtrosRecursosFecha": null,
        "zOtrosRecursosEntidadID": null
    }

    var informacionCuentas = {
        "SAP_UUID": codes["InformacionCuentas"],
        "zFechaAprobacion": null,
        "zFuentesFinanciamientoID": null,
        "zEntidadCreditoID": null
    }

    if (step4.ahorroprevio && !emptyJson(step4.ahorroprevio)) {
        var aux1 = [];
        var aux2 = [];
        step4.ahorroprevio.forEach(element => {
            if (element.tiporecursoA.value === "1") {
                aux1.push(element);
            }
            if (element.tiporecursoA.value === "2") {
                aux2.push(element);
            }
        });

        if (aux1.length > 1) {
            var element1 = yield mayor(aux1);
            recursoseconomicos.zCuentaAhorroProg = {
                "value": element1.safeValueA,
                "currencyCode": "COP",
            }
            recursoseconomicos.zCuentaAhorroProgFecha = element1.openDateA;
            recursoseconomicos.zCuentaAhorroProgEntidadID = element1.entityA;
        } else if (aux1.length === 1) {
            recursoseconomicos.zCuentaAhorroProg = {
                "value": aux1[0].safeValueA.value,
                "currencyCode": "COP",
            }
            recursoseconomicos.zCuentaAhorroProgFecha = aux1[0].openDateA.value;
            recursoseconomicos.zCuentaAhorroProgEntidadID = aux1[0].entityA.value;
        }

        if (aux2.length > 1) {
            var element2 = yield mayor(aux2);
            recursoseconomicos.zCesantias = {
                "value": element2.safeValueA,
                "currencyCode": "COP",
            }
            recursoseconomicos.zCesantiasFecha = element2.openDateA;
            recursoseconomicos.zCesantiasEntidadID = element2.entityA;
        } else if (aux2.length === 1) {
            recursoseconomicos.zCesantias = {
                "value": aux2[0].safeValueA.value,
                "currencyCode": "COP",
            }
            recursoseconomicos.zCesantiasFecha = aux2[0].openDateA.value;
            recursoseconomicos.zCesantiasEntidadID = aux2[0].entityA.value;
        }

        step4.ahorroprevio.forEach(element => {

            if (element.tiporecursoA.value === "4") {
                recursoseconomicos.zCuentaAhorroContractual = {
                    "value": element.safeValueA.value,
                    "currencyCode": "COP",
                }
                recursoseconomicos.zCuentaAhorroContractualFecha = element.openDateA.value;
                recursoseconomicos.zCuentaAhorroContractualEntidadID = element.entityA.value;
            }
            if (element.tiporecursoA.value === "5") {
                recursoseconomicos.zApotesPeriodicos = {
                    "value": element.safeValueA.value,
                    "currencyCode": "COP",
                }
                recursoseconomicos.zAportesPeriodicosFecha = element.openDateA.value;
                recursoseconomicos.zAportesPeriodicosEntidadID = element.entityA.value;
            }

            if (element.tiporecursoA.value === "6") {
                recursoseconomicos.zCuotaInicial = {
                    "value": element.safeValueA.value,
                    "currencyCode": "COP",
                }
                recursoseconomicos.zCuotaInicialFecha = element.openDateA.value;
                recursoseconomicos.zCuotaInicialEntidadID = element.entityA.value;
            }
            if (element.tiporecursoA.value === "7") {
                recursoseconomicos.zAporteLoteTerreno = {
                    "value": element.safeValueA.value,
                    "currencyCode": "COP",
                }
                recursoseconomicos.zAporteLoteTerrenoFecha = element.openDateA.value;
                recursoseconomicos.zAporteLoteTerrenoEntidadID = element.entityA.value;
            }
            if (element.tiporecursoA.value === "8") {
                recursoseconomicos.zAporteAvance = {
                    "value": element.safeValueA.value,
                    "currencyCode": "COP",
                }
                recursoseconomicos.zAporteAvanceFecha = element.openDateA.value;
                recursoseconomicos.zAporteAvanceEntidadID = element.entityA.value;
            }
            if (element.tiporecursoA.value === "9") {
                recursoseconomicos.zOtrosRecursos = {
                    "value": element.safeValueA.value,
                    "currencyCode": "COP",
                }
                recursoseconomicos.zOtrosRecursosFecha = element.openDateA.value;
                recursoseconomicos.zOtrosRecursosEntidadID = element.entityA.value;
            }
        });
        recursoseconomicos.zTotalAhorroPrevio = {
            "value": step4.financiaciontotal.totalahorroprevio,
            "currencyCode": "COP"
        }

    }
    if (step4?.cuentasCredito) {
        informacionCuentas = {
            "SAP_UUID": codes["InformacionCuentas"],
            "zFechaAprobacion": emptyObject(step4?.cuentasCredito?.openDateFuente),
            "zFuentesFinanciamientoID": emptyObject(step4?.cuentasCredito?.fuentesFinanciamiento),
            "zEntidadCreditoID": emptyObject(step4?.cuentasCredito?.entityFuente),
        }
    }
    if (step4.recursoscomplementarios && !emptyJson(step4.recursoscomplementarios)) {
        step4.recursoscomplementarios.forEach(element => {
            if (element.tiporecursoR.value === "1") {
                recursoseconomicos.zCreditoAprobado = {
                    "value": element.safeValueR.value,
                    "currencyCode": "COP"
                };
            }
            if (element.tiporecursoR.value === "2") {
                recursoseconomicos.zAportesSolitarios = {
                    "value": element.safeValueR.value,
                    "currencyCode": "COP"
                };
            }
            if (element.tiporecursoR.value === "3") {
                recursoseconomicos.zAportesEnteTerr = {
                    "value": element.safeValueR.value,
                    "currencyCode": "COP"
                };
            }
            if (element.tiporecursoR.value === "4") {
                recursoseconomicos.zDonacion = {
                    "value": element.safeValueR.value,
                    "currencyCode": "COP"
                };
            }
            if (element.tiporecursoR.value === "5") {
                recursoseconomicos.zOtrosRecursosComple = {
                    "value": element.safeValueR.value,
                    "currencyCode": "COP"
                };
            }

        });
        recursoseconomicos.zTotalRecursos = {
            "value": step4.financiaciontotal.totalrecursos,
            "currencyCode": "COP"
        }
    }
    var financiacion = {
        "SAP_UUID": codes["FinanciacionTotal"],
        "zAhorroPrevioFinan": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zRecursosComplementarios": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zSolictarValorSubsidio": "2",
        "zSubsidioSolitado": {
            "value": "0.00",
            "currencyCode": "COP"
        },
        "zValorSFV": null,
        "zValorSubsidio": {
            "value": "0",
            "currencyCode": "COP"
        },
        "zValorTotalNuevaUsada": {
            "value": "0.00",
            "currencyCode": "COP"
        }
    }

    var totalIngresos = 0;
    var ingresosAfiliado = emptyCero(step2.condicionHogar.ingresos);
    if (step2.totalingresos) {
        var ttIngre = parseInt(step2.totalingresos?.totalingresos);
        var liqui = parseInt(step2.totalingresos?.salarioreportafiliacion);
        var ingre = parseInt(ingresosAfiliado);
        var ingreGrupo = ttIngre - ingre;
        totalIngresos = ingre + ingreGrupo;
        if (liqui > ingre) {
            totalIngresos = liqui + ingreGrupo;
            ingresosAfiliado = liqui.toString();
        }
    }

    if (step4.financiaciontotal) {
        financiacion = {
            "SAP_UUID": codes["FinanciacionTotal"],
            "zAhorroPrevioFinan": {
                "value": emptyCero(step4.financiaciontotal.totalahorroprevio),
                "currencyCode": "COP"
            },
            "zRecursosComplementarios": {
                "value": emptyCero(step4.financiaciontotal.totalrecursos),
                "currencyCode": "COP"
            },
            "zSolictarValorSubsidio": "1",
            "zSubsidioSolitado": {
                "value": emptyCero(step4.financiaciontotal.valorsubcidiado),
                "currencyCode": "COP"
            },
            "zValorSFV": null,
            "zValorSubsidio": {
                "value": "0",
                "currencyCode": "COP"
            },
            "zValorTotalNuevaUsada": {
                "value": emptyCero(step4.financiaciontotal.valorvivienda),
                "currencyCode": "COP"
            }
        }
    }

    var valorSolucion = {
        "SAP_UUID": codes["ValorSolucionConstruccion"],
        "zValorPresupuesto": {
            "value": "0",
            "currencyCode": "COP"
        },
        "zValorLote": {
            "value": "0",
            "currencyCode": "COP"
        }
    }
    if (step3.valorconstruccion) {
        valorSolucion = {
            "SAP_UUID": codes["ValorSolucionConstruccion"],
            "zValorPresupuesto": {
                "value": step3.valorconstruccion.presupuesto,
                "currencyCode": "COP"
            },
            "zValorLote": {
                "value": step3.valorconstruccion.avaluocatastral,
                "currencyCode": "COP"
            }
        }
    }

    const headers = HEADERS_WS;


    const body = {

        "requestMessageIn": {
            "header": {
                "invokerDateTime": date,
                "moduleId": "VIVIENDA",
                "systemId": "PORTAL",
                "messageId": messageid,
                "logginData": {
                    "sourceSystemId": "",
                    "destinationSystemId": ""
                },
                "destination": {
                    "namespace": "http://co/com/comfenalcovalle/esb/ws/InQueueCRMFormularioSubsidioVivienda",
                    "name": "CRMFormularioSubsidioVivienda",
                    "operation": "execute"
                },
                "classification": {
                    "classification": ""
                }
            },
            "body": {
                "request": {
                    "actionCode": "02",
                    "SAP_UUID": codes["inicio"],
                    "zCodigoOportunidad": emptyObject(oportunidad),
                    "Importante": {
                        "SAP_UUID": codes["Importante"],
                        "zOpcionSiNo": emptyObject(step3?.informacionbasica?.subsidio),
                        "zNombreEntidad": emptyObject(step3?.informacionbasica?.entidadSubsidio)
                    },
                    "ModalidadVivienda": {
                        "SAP_UUID": codes["ModalidadVivienda"],
                        "zModalidadVivienda2": emptyObject(step3?.informacionbasica?.homeModeType),
                    },
                    "TipoAfiliado": {
                        "SAP_UUID": codes["TipoAfiliado"],
                        "zTipoAfiliado": emptyObject(step3?.informacionbasica?.postulanteType),
                    },
                    "HogarPostulante": {
                        "SAP_UUID": codes["HogarPostulante"],
                        "zNombreAfiliado": emptyObject(step2.condicionHogar.companyName),
                        "zTipoDocumento": emptyObject(step1.datospostulante.documentType),
                        "zDocumentoIdentidad": emptyObject(step1.datospostulante.numberId),
                        "zExpedida": emptyObject(step1.datospostulante.idPlace),
                        "zDireccionActual": emptyObject(step1.datospostulante.currentAddress),
                        "zCorreoElectronico": emptyObject(step1.datospostulante.companyEmail),
                        "zDepartamento": emptyObject(step1.datospostulante.departament),
                        "zMunicipio": emptyObject(step1.datospostulante.city),
                        "zBarrio": emptyObject(step1.datospostulante.hoodId),
                        "zTelfono1": dividePhone(emptyObject(step1.datospostulante.companyPhone), "telefono1"),
                        "zNIT": emptyObject(step1.informacionlaboral.subsidioData.nit),
                        "zEmpresaAfiliacion": emptyObject(step1.informacionlaboral.subsidioData.name),
                        "zDireccionEmpresa": emptyObject(step1.informacionlaboral.subsidioData.place),
                        "zTelfono2": dividePhone(emptyObject(step1.datospostulante.companyPhone), "telefono2"),
                        "zEmpresaTraba": laEMPname,
                        "zDireccionTrabajo": laEMPplace,
                        "zTelfono3": laEMPphone,
                    },
                    "DatoAfiliado": {
                        "SAP_UUID": codes["DatoAfiliado"],
                        "zNombreApellido": emptyObject(step2.condicionHogar.companyName),
                        "zFechaNacimiento": emptyObject(step2.condicionHogar.birthdate),
                        "zTipoDocumento": emptyObject(step2.condicionHogar.documentType),
                        "zDocIdentididadAfiliado": emptyObject(step2.condicionHogar.numDocJefe),
                        "zSexoAfiliado": homologaSexo(emptyObject(step2.condicionHogar.sex)),
                        "zParentescoAfil": "1",
                        "zOcupacionAfil": emptyObject(step2.condicionHogar.jobJefeHogar),
                        "zEstadoCivilAfil": emptyObject(step2.condicionHogar.maritalstatusJefe),
                        "zipoPostulanteAfil2": emptyObject(step2.condicionHogar.tipostulante),
                        "zIngresoMensualAfil2": {
                            "value": ingresosAfiliado,
                            "currencyCode": "COP"
                        },
                        "zCondicionEspecialAfil": emptyObject(step2.condicionHogar.special),
                        "zNombreCaja": "Comfenalco Valle"
                    },
                    "IntegrantesHogarPostulante": inthogarPostulan,

                    "Totales": {
                        "SAP_UUID": codes["Totales"],
                        "zObservaciones": emptyObject(step2.totalingresos.observaciones),
                        "zTotalIngresos": {
                            "value": totalIngresos.toString(),
                            "currencyCode": "COP"
                        }
                    },
                    "InformacionPostulacion": {
                        "SAP_UUID": codes["InformacionPostulacion"],
                        "zNombreProyecto": emptyObject(step3?.informacionpostulacion?.projectname),
                        "zNombreOferente": emptyObject(step3?.informacionpostulacion?.ofername),
                        "zDepartamentoApli": emptyObject(step3?.informacionpostulacion?.departamentPostu),
                        "zMunicipioApli": emptyObject(step3?.informacionpostulacion?.cityPostu)
                    },
                    //TODO: barrio no lo solicita
                    "ModalidadViviendaV2": modalidadvivienda,
                    "RecursoEconomico": recursoseconomicos,
                    "FinanciacionTotal": financiacion,
                    "ValorSolucionConstruccion": valorSolucion,
                    "InformacionCuentas": informacionCuentas,
                    "Autorizacion": {
                        "SAP_UUID": codes["Autorizacion"],
                        "zCorreoElectronico2": "true"
                    }
                }
            }
        }

    }
    console.log("body vivienda ", JSON.stringify(body))
    var resul = {}
    try {
        console.time("inicio");


        const res = yield consumeServiceGenerico({ body, url: urlConfenalco })

        console.log("respuesta ws vivienda", res)
        var ban = false;
        let response = { nota: "Actualización exitosa", faseVenta: "Z01" };

        if (res.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
            resul = res.responseMessageOut.body.response.registroSubsidioResponse;


        } else {

            response = {
                nota: emptyObject(res.responseMessageOut.body.response && res.responseMessageOut.body.response.registroSubsidioResponse && res.responseMessageOut.body.response.registroSubsidioResponse.log ? res.responseMessageOut.body.response.registroSubsidioResponse.log : "Time out"),
                faseVenta: "Z11",
                motivoInhabilita: "Z75",
                estado: "5",
            };

        }
        resul = response;

        console.timeEnd("inicio");

    } catch (error) {


        console.error(error, "error")
    }
    return resul;
}

function* pdfgenera(action) {
    /* función que recibe los datos del formulario, genera el pdf con esos datos
      luego saca el base64 de ese pdf y lo almacena en local con los otros archivos adjuntos
      con el nombre de pdfFormulario
     */
    const { id, items, oportunidad } = action;
    try {
        const resul = yield pdf(<MyDocument data={items} />).toBlob();
        let response = false;
        if (resul) {
            const idFormulario = yield select(getIdFormulario);
            const complement = ` ${yield getFechaOfDateV3()}`
            const nameFile = `formularioViviendaAfiliacion ${emptyObject(complement)}.pdf`
            const refFil = `${idFormulario}/pdfAfiliacion/${nameFile}`;


            let exist = false;
            let refFiles = items.refFiles.refStorage.filter(e => e.classification !== "formularioAfiliacion");
            if (refFiles.length > 0)
                refFiles.forEach(e => {
                    if (e.refFile === refFil) exist = true;
                });
            if (!exist) {
                const fireStorageRef = firebaseStorage.ref();
                const fileReference = fireStorageRef.child(refFil);
                const snapshot = yield call(uploadBytes, fileReference, resul);

                refFiles.push({
                    nameArchivo: nameFile,
                    type: "application/pdf",
                    refFile: refFil,
                    classification: "formularioAfiliacion"
                });
                response = refFiles;
                yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA).doc(idFormulario).set({ refFiles: { refStorage: refFiles } }, { merge: true });

            }
        }
        return response;
    } catch (error) {
        console.error("function.pdfGenera", error);
        throw `pdfGenera - ${error}`;
    }
}

function* savedActionsHistory(action) {
    /*
función que recibe las dos acciones posibles para el asesor comercial 
devolver, anular y aprobar y realiza su logica respecto a a accion solicitada
*/
    try {
        console.log("Loading", action)
        const { value: { messageConfirmation: { data, actionValid, from, dataForm }, itemsForm, catalogo } } = action;

        yield put(closeModales());
        yield put(openModalCharged(true));
        const userInfo = yield select(getUserInfo);
        const idFormulario = from === "table" ? dataForm.idFormulario : yield select(getIdFormulario);
        const actionsForm = actionValid.toUpperCase();
        const datosForm = from === "table" ? dataForm : itemsForm;
        let history = {
            fechaHistory: new Date(),
            tomado: userInfo.rol,
            infoUsuario: userInfo,
            resultadoValidacion: {},
        }



        let stateReg = false;

        // //bandera que define si se actualiza el estado de la afiliación
        let procesoExitoso = false;

        if (actionsForm === "DEVOLVER") {
            history.resultadoValidacion = {

                fechaValidacion: new Date(),
                accionRealizada: actionsForm,
                motivo: data.datosdevolucion?.motivoDevolucion,
                observacion: emptyObject(data.datosdevolucion?.observation),
            }
            stateReg = updateState.DEVUELTA;

            //TODO consume servicio actualiza la oportunidad
            const catalog = yield select(getDynamicOptions)
            const idMotivo = yield retornaIdCatalog(catalog.motivoDevolucion, history.resultadoValidacion.motivo);
            const nota = `Información devolución comercial\n\nObservacion: ${emptyObject(history.resultadoValidacion.observacion)} \nMotivo: ${emptyObject(history.resultadoValidacion.motivo)}`;
            const resultDevolver = yield consumeActualizaOportunidad({ faseVenta: false, idMotivo, anexos: false, itemsForm: datosForm, nota, idFormulario: false, estado: false });
            if (resultDevolver) {
                // const dataEmail = {
                //     motivo: history.resultadoValidacion.motivo,
                //      observacion: history.resultadoValidacion.observacion, 
                //      numRegistro: datosForm.idOportunidad
                // }
                // const resul = yield pdf(<MyPdfCartas data={ } />).toBlob();

                procesoExitoso = true;
                // if (resul) {
                //     const res = yield getBase64(resul)
                //     const files = {
                //         filename: 'carta.pdf', // Nombre del archivo adjunto
                //         content: res.split("data:application/pdf;base64,")[1], // Contenido codificado en Base64
                //         encoding: 'base64', // Especificar la codificación
                //     }
                //enviarEmailGenerico({ from: "Notificaciones bandeja comercial", to: datosForm.step1.datospostulante.companyEmail, subject: "Notificación de devolución parte de validación comercial", message: "", files });
                enviarEmailFormatDocument({ from: "Notificaciones bandeja comercial", to: datosForm.step1.datospostulante.companyEmail, subject: "Notificación de devolución por parte de validación comercial", type: "DEVOLUCION", motivo: history.resultadoValidacion.motivo, observacion: history.resultadoValidacion.observacion, numRegistro: datosForm.idOportunidad});

                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgDevolSuccess"),
                        error: false,
                        home: true
                    }))

                yield obtenerDatosRegVivienda({});

            } else {
                yield put(openModalCharged(false));

                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgDevolFailure"),
                        error: true,
                    }))


                history.resultadoValidacion = {
                    fechaValidacion: new Date(),
                    accionRealizada: "Actualizar oportunidad en devolución",
                    observacion: `No realizado, el servicio de actualizar no respondio`
                }

                saveHistoryViviendaActualizacion({ history, idFormulario })
            }


        } else if (actionsForm.includes("ANULAR")) {
            history.resultadoValidacion = {
                fechaValidacion: new Date(),
                accionRealizada: actionsForm,
                motivo: data.datosanulacion?.motivoAnulacion,
                observacion: emptyObject(data.datosanulacion?.observationAnulation),
            }
            stateReg = updateState.ANULADA;

            const catalog = yield select(getDynamicOptions)
            const idMotivo = yield retornaIdCatalog(catalog.motivoAnulacion, history.resultadoValidacion.motivo);
            const nota = `Información anulación o rechazo\n\nObservacion: ${emptyObject(history.resultadoValidacion.observacion)} \nMotivo: ${emptyObject(history.resultadoValidacion.motivo)}`;
            const resultAnular = yield consumeActualizaOportunidad({ faseVenta: false, idMotivo, anexos: false, itemsForm: datosForm, nota, idFormulario: false, estado: false });
            if (resultAnular) {
                procesoExitoso = true;

                enviarEmailFormatDocument({ from: "Notificaciones bandeja comercial", to: datosForm.step1.datospostulante.companyEmail, subject: "Notificación de anulación por parte de validación comercial", type: "ANULACION", motivo: history.resultadoValidacion.motivo, observacion: history.resultadoValidacion.observacion })

                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgAnulaSuccess"),
                        error: false,
                        home: true
                    }))

                yield obtenerDatosRegVivienda({});

            } else {
                yield put(openModalCharged(false));
                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgAnulaFailure"),
                        error: true,
                    }))


                history.resultadoValidacion = {
                    fechaValidacion: new Date(),
                    accionRealizada: "Actualizar oportunidad en anulación",
                    observacion: `No realizado, el servicio de actualizar no respondio`
                }

                saveHistoryViviendaActualizacion({ history, idFormulario })
            }
        } else if (actionsForm.includes("AFILIAR")) {

            stateReg = updateState.FINALIZADA;
            const solicitudes = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA).doc(idFormulario).get();
            const items = solicitudes.data();
            const responseFiles = yield pdfgenera({ id: idFormulario, items: { ...items }, oportunidad: items.oportunidad });

            if (items) {

                const resCodeOp = yield consumeConsultaCodeOportunidad({ itemsForm: items });
                let codes = false;
                if (resCodeOp) {
                    codes = yield filterCodeOportunidad(resCodeOp);
                }
                if (codes) {
                    const grabaVivienda = yield consumeWsVivienda({ oportunidad: items.oportunidad, items, idFormulario, catalogo, codes });
                    console.log(grabaVivienda)
                    if (grabaVivienda) {

                        if (grabaVivienda.faseVenta === "Z01") {
                            let copyItems = { ...items }
                            copyItems.response = grabaVivienda;
                            if (responseFiles)
                                copyItems.refFiles.refStorage = responseFiles;
                            const responseOportunidad = yield consumeServiceGenerico({ body: { data: { ...copyItems } }, url: process.env.REACT_APP_FIREBASE_GRABA_ANEXOS_ACTUALIZACION_VIVIENDA })
                            if (responseOportunidad) {
                                procesoExitoso = true;
                            } else {
                                yield put(openModalCharged(false));
                                const msgParametrizados = yield select(getMsgParametrizados);
                                yield put(messageAlertSend(
                                    {
                                        message: yield generateMsgParametrizado(msgParametrizados, "msgAfiliaFailure"),
                                        error: true,
                                    }))


                                history.resultadoValidacion = {
                                    fechaValidacion: new Date(),
                                    accionRealizada: "Actualizar oportunidad en afiliación",
                                    observacion: `No realizado, el servicio de actualizar no respondio`
                                }

                                saveHistoryViviendaActualizacion({ history, idFormulario })
                            }
                        } else {
                            yield put(openModalCharged(false));
                            history.resultadoValidacion = {
                                fechaValidacion: new Date(),
                                accionRealizada: "Actualizar información en afiliación",
                                observacion: `se ha encontrado una inconsistencia que no permite realizar radicación de la postulación ${grabaVivienda.nota}`
                            }

                            saveHistoryViviendaActualizacion({ history, idFormulario })
                            yield put(messageAlertSend(
                                {
                                    message: `De acuerdo al registro realizado, se ha encontrado una inconsistencia que no permite realizar radicación de tu postulación en este momento. ${emptyObject(grabaVivienda.nota)}`,
                                    error: true,
                                }))
                        }

                        //TODO consume actualiza oportunidad backend
                    } else {
                        yield put(openModalCharged(false));
                        const msgParametrizados = yield select(getMsgParametrizados);
                        yield put(messageAlertSend(
                            {
                                message: `${yield generateMsgParametrizado(msgParametrizados, "msgAfiliaFailure")}. ${emptyObject(grabaVivienda.nota)}`,
                                error: true,
                            }))


                        history.resultadoValidacion = {
                            fechaValidacion: new Date(),
                            accionRealizada: "Actualizar información en afiliación",
                            observacion: `No realizado, el servicio de vivienda de crm no respondio`
                        }

                        saveHistoryViviendaActualizacion({ history, idFormulario })
                    }
                } else {
                    yield put(openModalCharged(false));
                    const msgParametrizados = yield select(getMsgParametrizados);
                    yield put(messageAlertSend(
                        {
                            message: yield generateMsgParametrizado(msgParametrizados, "msgAfiliaFailure"),
                            error: true,
                        }))


                    history.resultadoValidacion = {
                        fechaValidacion: new Date(),
                        accionRealizada: "Actualizar información en afiliación",
                        observacion: `No realizado, el servicio de vivienda de crm no respondio`
                    }

                    saveHistoryViviendaActualizacion({ history, idFormulario })
                }
            } else {
                yield put(openModalCharged(false));
                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgAfiliaFailure"),
                        error: true,
                    }))
            }
        } else if (actionsForm.includes("ASIGNAR")) {
            history.resultadoValidacion = {
                fechaValidacion: new Date(),
                accionRealizada: actionsForm,
                motivo: "Asignar asesor",
                observacion: `Se asignó a : ,${data.datosasignacion.asesorDisponible}, a la postulación`,
            }

            const asesorComercial = {
                email: data.datosasignacion.emailAsesor,
                name: data.datosasignacion.asesorDisponible
            }
            yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                .doc(idFormulario).set({ asesorComercial }, { merge: true });
            procesoExitoso = true;

            // enviarEmailFormatDocument({ from: "Notificaciones bandeja comercial", to: datosForm.step1.datospostulante.companyEmail, subject: "Notificación de anulación de empresa por parte de validación comercial", type: "ANULACION", motivo: history.resultadoValidacion.motivo, observacion: history.resultadoValidacion.observacion })

            const msgParametrizados = yield select(getMsgParametrizados);
            yield put(messageAlertSend(
                {
                    message: yield generateMsgParametrizado(msgParametrizados, "msgAsignaSuccess"),
                    error: false,
                    home: true
                }))

            yield obtenerDatosRegVivienda({});


        }
        if (procesoExitoso) {

            if (actionsForm === "DEVOLVER") {
                const resDevolucion = {
                    motivo: data.datosdevolucion?.motivoDevolucion,
                    fecha: history.fechaHistory,
                    observacion: emptyObject(data.datosdevolucion?.observation),
                    user: userInfo
                }
                console.log(idFormulario, stateReg, resDevolucion)
                yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                    .doc(idFormulario).set({ state: stateReg, devolucion: resDevolucion }, { merge: true });

            } else if (actionsForm === "ANULAR") {
                const anulacion = {
                    fecha: history.fechaHistory,
                    motivo: history.resultadoValidacion.motivo,
                    observacion: emptyObject(history.resultadoValidacion.observacion),
                    user: userInfo
                }
                yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                    .doc(idFormulario).set({ state: stateReg, anulacion }, { merge: true });

            } else if (actionsForm === "AFILIAR") {
                const afiliacion = {
                    fecha: history.fechaHistory,
                    user: userInfo
                }
                yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_ACTUALIZACION_VIVIENDA)
                    .doc(idFormulario).set({ state: stateReg, afiliacion }, { merge: true });

                const msgParametrizados = yield select(getMsgParametrizados);
                yield put(messageAlertSend(
                    {
                        message: yield generateMsgParametrizado(msgParametrizados, "msgAfiliaSuccess"),
                        error: false,
                        home: true
                    }))


                yield obtenerDatosRegVivienda({});
            }
            saveHistoryViviendaActualizacion({ history, idFormulario })

            yield put(openModalCharged(false));

        } else {
            //No se pudo realizar algun proceso, sea DEVOLVER, ANULAR o APROBAR
            yield put(openModalCharged(false));

        }

    } catch (error) {
        console.error("Saga.savedActionsHistory", error);
        yield put(errorGeneral(manejoDeexepciones(error)));
    }
}

export function* watchComponent() {
    yield takeLatest(LOAD_CATALOG_BANDEJA, buildDynamicOptions);
    yield takeLatest(VALIDATE_USER_TYPE, initValidateUser);
    yield takeLatest(PAGINAR_DATA_REG_VIVIENDA, paginarDataRegVivienda);
    yield takeLatest(SEARCH_FORM_FROM_TABLE, searchFormFromTable);
    yield takeLatest(OPEN_SEE_FILES_UNIFIED, openSeeFilesUnified);
    yield takeLatest(REDUX_CHANGE, onReduxChange);
    yield takeLatest(REDUX_BLUR, onreduxBlur);
    yield takeLatest(DEVOLVER_POSTULATION_FROM_TABLE, devolverPostulationTable);
    yield takeLatest(ANULAR_POSTULATION_FROM_TABLE, anularPostulationTable);
    yield takeLatest(SEARCH_HISTORIAL_BANDEJA, searchHistorialform);
    yield takeLatest(ACTIONS_OF_APROVE, onActionsAprove);
    yield takeLatest(RETURN_HOME_BANDEJA, recoveryTableAfiliations);
    yield takeLatest(OPEN_ANULATION_FORM, onOpenAnulationForm);
    yield takeLatest(SAVED_ACTIONS_VALIDATION, savedActionsHistory);
    yield takeLatest(EXPORT_DATA_FORM_XLSX, exportIntentToExcel);
    yield takeLatest(ASIGNA_ASESOR_FORM, onAsignaAsesorForm)

}
